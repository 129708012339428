import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button';
import Icon from '../Icon';
import Subscribe from '../Subscribe';
import Modal from '../Modal';
import Video from '../Video';
import classNames from 'classnames';
import './styles.scss';

const Action = ({
  label,
  metadata,
  module,
  moduleName,
  link,
  download,
  downloadInverse,
  noIcon,
  asLink,
  signUp,
  videoModal,
}) => {
  const renderSignUpForm = () => {
    return asLink ? (
      <Subscribe
        trigger={
          <a data-cep-ignore-click className="resource__action-link">
            Sign up for updates
            <span style={{ fontSize: '1.25rem' }}> &rsaquo;</span>
          </a>
        }
      />
    ) : (
      <Subscribe
        trigger={
          <Button data-cep-ignore-click className="resource__action-link">
            Sign up for updates
            <span style={{ fontSize: '1.25rem' }}> &rsaquo;</span>
          </Button>
        }
      />
    );
  };

  const renderVideoModal = () => {
    return (
      <Modal
        trigger={
          <Button
            className="resource__action-link"
            link={asLink}
            inverse={downloadInverse}
          >
            {!noIcon && (
              <Icon
                name="play"
                className={classNames(
                  downloadInverse
                    ? 'resource__action-icon__inverse'
                    : 'resource__action-icon'
                )}
                marginRight
              />
            )}
            {label}
          </Button>
        }
      >
        <Video guidSrc={videoModal} />
      </Modal>
    );
  };

  const renderModuleLink = () => {
    return (
      <Button
        to={module}
        target="_blank"
        forceExternal
        data-click-type="Module Launch"
        data-click-name={moduleName}
        className="resource__action-link"
        inverse
      >
        {!noIcon && (
          <Icon
            marginRight
            name="externallink"
            className="resource__action-icon__inverse"
          />
        )}
        {label}
      </Button>
    );
  };

  const renderLink = () => {
    return asLink ? (
      <Link to={link} className="resource__action-link">
        {!noIcon && (
          <Icon marginRight name="module" className="resource__action-icon" />
        )}
        {label}
        <span style={{ fontSize: '1.25rem' }}> &rsaquo;</span>
      </Link>
    ) : (
      <Button to={link} className="resource__action-link">
        {label}
      </Button>
    );
  };

  const renderDownload = () => {
    return (
      <Button
        to={download}
        target="_blank"
        rel="noopener noreferrer"
        className="resource__action-link"
        link={asLink}
        inverse={downloadInverse}
      >
        {!noIcon && (
          <Icon
            name="download"
            className={classNames(
              downloadInverse
                ? 'resource__action-icon__inverse'
                : 'resource__action-icon'
            )}
            marginRight
          />
        )}
        {label}
      </Button>
    );
  };

  return (
    <div className="resource__action">
      {signUp && renderSignUpForm()}
      {link && renderLink()}
      {module && renderModuleLink()}
      {download && renderDownload()}
      {videoModal && renderVideoModal()}
      {metadata && <span className="resource__metadata">{metadata}</span>}
    </div>
  );
};

Action.propTypes = {
  label: PropTypes.node.isRequired,
  metadata: PropTypes.string,
  module: PropTypes.string,
  download: PropTypes.string,
  noIcon: PropTypes.bool,
  asLink: PropTypes.bool,
  signUp: PropTypes.bool,
};

export default Action;
