import React from 'react';
import { Column, Container, Row } from '../components/Grid';
import Hero from '../components/Hero';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Quote from '../components/Quote';
import Section from '../components/Section';
import Resource from '../components/Resource';

const IndexPage = ({ data }) => (
  <Layout title="Career Profiles">
    <Hero
      mediaSource="05-careers-hero.jpg"
      textOverlay="CAREER PROFILES"
      className="mb-0"
    />
    <Section noPadding>
      <Container className="career-profiles-discover__container">
        <Row>
          <Column size={7} offset={1}>
            <h1 className="career-profiles-discover__header-text">
              Discover the Exciting and Diverse Career Paths in the Country
              Music Industry
            </h1>
            <p>
              Explore the fascinating world of Country Music through our video
              activity bundles. Delve into the various career opportunities
              available and learn what it takes to be one of the creative
              professionals working behind the scenes as well as on the stage.
              Making great music requires the collaboration of many talented
              individuals across different roles, all working together in
              harmony.
            </p>
          </Column>
          <Column size={4}>
            <Image filename="05-careers-graphic-piano.svg" />
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <Resource
              ribbon="activities-badge-new.png"
              tophat="Career Profile"
              audience="Grades 9-12"
              asCard
              img="05-career-profile-thumb-tvDirector.jpg"
              title="Television Director"
              description="A television director acts as the visual conductor of a recorded Country Music performance or special, planning and communicating the placement of the cameras, the cuts between shots, and the overall look of the production."
              actions={[
                {
                  videoModal: '8bc81a93-6f8e-4d42-82ec-2be41b4f52f1',
                  label: 'Watch Video',
                  metadata: '.MOV',
                },
                {
                  download: '/docs/CMA-CareerProfile-TelevisionDirection.pdf',
                  label: 'Career Profile',
                  metadata: '.PDF, 352KB',
                  downloadInverse: true,
                },
                {
                  download:
                    '/docs/CMA-CareerActivation-TelevisionDirection.pdf',
                  label: 'Student Activation',
                  metadata: '.PDF, 352KB',
                  downloadInverse: true,
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              ribbon="activities-badge-new.png"
              tophat="Career Profile"
              audience="Grades 9-12"
              asCard
              img="05-career-profile-thumb-marketingExec.jpg"
              title="Marketing Executive"
              description="In the Country Music industry, a marketing executive’s primary job is to spread the word about an artist, event, or new music release and get people excited about it so that it can reach the widest audience possible."
              actions={[
                {
                  videoModal: '7a44eabb-18bb-4a29-8feb-7419da29d144',
                  label: 'Watch Video',
                  metadata: '.MOV',
                },
                {
                  download:
                    '/docs/CMA-CareerProfile-MarketingExecutive-V0.2.pdf',
                  label: 'Career Profile',
                  metadata: '.PDF, 303KB',
                  downloadInverse: true,
                },
                {
                  download:
                    '/docs/CMA-CareerActivation-MarketingExecutive-V0.2.pdf',
                  label: 'Student Activation',
                  metadata: '.PDF, 306KB',
                  downloadInverse: true,
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              ribbon="activities-badge-new.png"
              tophat="Career Profile"
              audience="Grades 9-12"
              asCard
              img="05-career-profile-thumb-singerSongwriter.jpg"
              title="Singer Songwriter"
              description="Singer-songwriters are responsible for taking the seeds of musical ideas and creating your favorite Country Music. Country Music is all about storytelling so communication, collaboration, and creativity are an absolute must in this career."
              actions={[
                {
                  videoModal: '5a82b1cb-b8aa-4e2a-9e81-68ff3c87cb3a',
                  label: 'Watch Video',
                  metadata: '.MOV',
                },
                {
                  download: '/docs/CMA-CareerProfile-SingerSongwriter-V0.2.pdf',
                  label: 'Career Profile',
                  metadata: '.PDF, 284KB',
                  downloadInverse: true,
                },
                {
                  download:
                    '/docs/CMA-CareerActivation-SingerSongwriter-V0.2.pdf',
                  label: 'Student Activation',
                  metadata: '.PDF, 288KB',
                  downloadInverse: true,
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    <Quote
      text="There is a path for you to succeed in this industry!"
      source="Breland, Singer-Songwriter"
      backgroundImage="05-careers-block-quote.png"
      className="bg-white"
    />
  </Layout>
);

export default IndexPage;
